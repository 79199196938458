<template>
  <el-upload
      class="avatar-uploader"
      action="/api/v1/avatar/upload"
      accept="image/*"
      name="avatar"
      :show-file-list="false"
      :on-success="handleAvatarSuccess"
      :before-upload="beforeAvatarUpload"
      :file-list="fileList"
      :auto-upload="true"
      v-loading="busy"
  >
    <el-image v-if="imageUrl" :src="imageUrl" class="avatar" fit="contain" />
    <el-icon v-else class="avatar-uploader-icon"><Avatar></Avatar></el-icon>
  </el-upload>
  <el-card class="avatar-card" v-loading="busy">
    <template #header>
      <span>卡通化肖像</span>
    </template>
    <canvas ref="canvasStage" class="canvas"></canvas>
  </el-card>
</template>
<script setup>
import {onMounted, ref} from 'vue'
import { Avatar } from '@element-plus/icons-vue'
import {ElMessage} from "element-plus";
// eslint-disable-next-line no-unused-vars
import { fabric } from "fabric";

const fileList = ref([])
const imageUrl = ref()
const cartoonUrl = ref()
const canvasStage = ref()

let canvasResolver = null
const canvasPromise = new Promise((resolve) => {
  canvasResolver = resolve
});

let busy = false

const fabricAddImage = function (url,options) {
  if(options === undefined){
    options = {};
  }
  return new Promise((resolve) => {
    fabric.Image.fromURL(url, (img) => {
      resolve(img)
    },options);
  });
}

const handleAvatarSuccess = (
    response,
    uploadFile
) => {
  if(response.status === 'success') {
    imageUrl.value = response.raw
    if(response.cartoon){
      canvasPromise.then((canvas)=>{
        Promise.all([
            fabricAddImage(response.cartoon,{crossOrigin: 'anonymous'}),
            fabricAddImage('/hat3.png'),
        ]).then(([cartoon,hat])=>{
          canvas.add(cartoon
              .scale(0.6).set({
                left: 100,
                top: 100,
                perPixelTargetFind: true
            }));
          canvas.add(hat
              .scale(0.5).set({
                left: 100,
                top: 100,
                angle: -15,
                perPixelTargetFind: true
              })
          )
          busy = false
        });
      })
    }else {
      cartoonUrl.value = null
    }
  }else {
    imageUrl.value = URL.createObjectURL(uploadFile.raw)
  }
}


const beforeAvatarUpload = (rawFile) => {
  if (!rawFile.type.startsWith('image/')) {
    ElMessage.error('Avatar picture must be JPG format!')
    return false
  } else if (rawFile.size / 1024 / 1024 > 10) {
    ElMessage.error('Avatar picture size can not exceed 2MB!')
    return false
  }
  busy = true
  return true
}

onMounted(() => {
  const canvas = new fabric.Canvas(canvasStage.value,{
    width: 480,
    height: 480,
    preserveObjectStacking: true,
  })
  // const rect = new fabric.Rect({
  //   left: 0,
  //   top: 0,
  //   width: canvas.width,
  //   height: canvas.height,
  //   fill: '#e807a9',
  // })
  // canvas.add(rect)
  fabric.Image.fromURL('/wku_background.png', (img) => {
    img.set({
      left: (canvas.width - img.width)/2,
      top: 0,
      selectable: false,
    });
    canvas.add(img);
  });
  canvasResolver(canvas)
})

</script>
<style>
.avatar-uploader .el-upload {
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: var(--el-transition-duration-fast);
}

.avatar-uploader .el-upload:hover {
  border-color: var(--el-color-primary);
}

.el-icon.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  text-align: center;
  border: 1px dashed var(--el-border-color);
  border-radius: 6px;
}
</style>
<style scoped>
.avatar-card{
  width: max-content;
  margin: auto;
}
.avatar{
  width: 320px;
  height: 320px;
  border: 1px dashed var(--el-border-color);
  border-radius: 6px;
}
</style>
